import { redirect } from "next/navigation";
import axios from "axios";

export interface ICredentials {
  access: string;
  refresh: string;
}

export const api = axios.create({
  baseURL: `${process.env.API_BASE_URL}/api`,
});

export const setAuthHeader = (credentials?: ICredentials) => {
  if (credentials) {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${credentials.access}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const setForwardedForHeader = (ip: unknown) => {
  api.defaults.headers.common["X-Forwarded-For"] = ip as string;
};

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
      if (typeof window !== "undefined") {
        if (window.location.pathname !== "/login") {
          window.location.href = "/logout";
        }
      } else {
        redirect("/logout");
      }
    }

    return Promise.reject(err);
  }
);
